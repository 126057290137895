html {
  background-color: #007ace;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto,
    Segoe UI, Helvetica Neue, sans-serif;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 780px;
  font-family: sans-serif;
  color: #212b36;
  background-color: #f4f6f8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.fat-button {
  font-size: 1.5rem;
  padding: 8px;
  margin: 8px -8px;
}
/* --- */

.question-bank {
  display: flex;
  flex-direction: column;
}

.question-bank__item {
  display: block;
  font-size: 1.25rem;
  padding: 2px 0;
  margin-bottom: 12px;
}

.question-bank__item input {
  margin-right: 1rem;
  transform: scale(1.5) translateY(-1px);
  vertical-align: middle;
}

/* --- */

.quiz {
  display: flex;
  flex-direction: column;
}

.quiz__counter {
  margin: 16px 16px;
}

.question {
  display: none;
}

.question--active {
  display: block;
}

.header {
  color: #eec200;
  background-color: #212b36;
  padding: 12px 16px;
  margin: 0 -16px 8px;

  display: grid;
  grid-gap: 64px;
  grid-template-columns: auto auto;
}

.header__meta {
  text-align: right;
}

.next-button {
  font-size: 1.5rem;
  padding: 8px;
  margin: 8px -8px;
}

/* --- */

.answer {
  display: block;
  font-size: 1.75rem;
  padding: 16px 0;
}

.answer + .answer {
  border-top: 2px solid #eec200;
}

.answer--correct {
  color: green;
}

.answer--incorrect {
  color: red;
}

.answer__text {
}
.answer input {
  display: none;
}
